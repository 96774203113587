<template>
  <div>
    <div class="uk-margin-top uk-card uk-card-default uk-padding simp-card">
      <form
        class="uk-form-stacked"
        data-vv-scope="form-harvest-request"
        @submit.prevent="onUpload('form-harvest-request')"
      >
        <div class="uk-margin-bottom font-filter uk-text-bold">
          Import Data {{ title }}
        </div>
        <div class="uk-margin-small-bottom uk-text-bold uk-text-large">
          1. Pilih file yang ingin diupload:
        </div>
        <div>
          <p>Pilih File: </p>
          <p v-if="file_import">
            {{ file_import.name }}
          </p>
        </div>
        <div class="uk-margin">
          <div uk-form-custom="target: true">
            <input 
              ref="file_import" 
              v-validate="'required'"
              type="file"
              name="file"
              accept="text/csv"
              :disabled="btn_upload"
              :class="{'uk-form-danger': errors.has('form-harvest-request.file')}"
              @change="handleFileUpload"
            >
            <input
              class="uk-input uk-form-width-large"
              type="text"
              placeholder="Pilih .csv file"
              disabled
            >
            <button class="uk-button uk-button-upload">
              Browse
            </button>
          </div>
        </div>
        <span
          v-show="errors.has('form-harvest-request.file')"
          class="uk-text-small uk-text-danger"
        >{{ errors.first('form-harvest-request.file') }}</span>
        <div class="uk-text-small uk-text-secondary">
          Berikut ini template file excel dan pastikan upload sesuai template.
          <a href="/files/template-import/Import-Permintaan-Panen.csv">Klik disini</a>
        </div>
        <div class="uk-margin-top uk-margin-small-bottom uk-text-bold uk-text-large">
          2. Upload Disini:
        </div>
        <div class="uk-margin-small-bottom uk-form-controls">
          <button
            class="uk-button uk-button-primary uk-width-xlarge uk-margin-small-button"
            :disabled="btn_upload"
          >
            <span
              v-show="btn_upload != true"
              uk-icon="icon: upload; ratio: 0.9"
              class="uk-margin-small-right"
            />Upload Disini
          </button> 
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  props:{
    title:{
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      file_import: null,
      btn_upload: false
    }
  },
  computed:{
    ...mapGetters({
      responseImport: 'harvestRequest/responseImport'
    })
  },
  watch:{
    responseImport(){
      if(this.responseImport.message == 'OK'){
        notificationSuccess(`${this.title} berhasil di import`)
        this.btn_upload = false
        // this.resetInput()
        setTimeout(() => {
          window.location.href = '/admin/permintaan-panen'
        }, 1700)
      }
    }
  },
  methods: {
    ...mapActions({
      importHarvestRequest: 'harvestRequest/importHarvestRequest'
    }),
    async onUpload(scp) {
      this.btn_upload = true
      this.$validator.validateAll(scp).then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.uploadAction(scp)
        } else {
          this.btn_upload = false
        }
      })
    },
    async uploadAction(scp) {
      const formData = new FormData()
      if (scp === 'form-harvest-request') {
        formData.append('file', this.file_import)
        await this.$store.dispatch('harvestRequest/importHarvestRequest', formData)
      }
    },
    handleFileUpload(){
      this.file_import = this.$refs.file_import.files[0]
    },
    resetInput() {
      this.$refs.file_import.value = null
      this.handleFileUpload()
    }
  }
}
</script>

<style scoped>
.uk-button-upload{
  position: absolute;
  right: 0px;
  background: #F0F0F0;
  border: 1px solid #D4DFF5;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
}
.uk-text-large{
  color: black;
}
p{
  color: #6D7278;
}
.uk-form-controls{
  width: 500px;
  color:#4DA0E7;
}
</style>
